<template>
  <div class="user-wrapper">
    <div class="content-box">
      <v-select-country />
      <v-select-lang />
      <!-- <a href="https://pro.loacg.com/docs/getting-started" target="_blank">
        <span class="action">
          <a-icon type="question-circle-o"></a-icon>
        </span>
      </a> -->
      <!-- <notice-icon class="action"/> -->
      <a-dropdown>
        <span class="action ant-dropdown-link user-dropdown-menu">
          <!-- <a-avatar class="avatar" size="small" :src="avatar" /> -->
          <img src="../../assets/Gold.png" width="25px"/>
          <span style="margin-left: 10px;">{{ nickname }}</span>
        </span>
        <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
          <!-- <a-menu-item key="0">
            <router-link :to="{ name: 'center' }">
              <a-icon type="user"/>
              <span>个人中心</span>
            </router-link>
          </a-menu-item> -->
          <a-menu-item key="1">
            <div @click="handleModify">
              <a-icon type="setting" />
              <span> {{ $t('home.modifyPassword') }}</span>
            </div>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="3">
            <div @click="handleLogout">
              <a-icon type="logout" />
              <span> {{ $t('signOut') }}</span>
            </div>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <a-modal
      v-model="visible"
      :maskClosable="false"
      :confirmLoading="confirmLoading"
      :title="$t('home.modifyPassword')"
      @ok="submitPassword"
      @cancel="handleClosePassword"
    >
      <a-form :form="basicForm">
        <a-form-item :label="$t('oldPassword')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
          <a-input v-decorator="['oldPassword', validator.oldPassword]" type="password" />
        </a-form-item>
        <a-form-item :label="$t('newPassword')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
          <a-input v-decorator="['password', validator.password]" type="password" />
        </a-form-item>
        <a-form-item :label="$t('confirmPassword')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
          <a-input v-decorator="['confirmPassword', validator.confirmPassword]" type="password" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import NoticeIcon from '@/components/NoticeIcon'
import vSelectLang from './LangSelect'
import vSelectCountry from './CountrySelect'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UserMenu',
  components: {
    // PandaIcon, 
    NoticeIcon,
    vSelectLang,
    vSelectCountry,
  },
  data() {
    return {
      defaultLanguage: ['zh-CN'],
      confirmLoading: false,
      visible: false,
      basicForm: this.$form.createForm(this),
      validator: {
        oldPassword: {
          validateTrigger: 'blur',
          rules: [{ required: true, message: this.$t('enter') + ' ' + this.$t('oldPassword') }],
        },
        password: {
          validateTrigger: 'blur',
          rules: [{ required: true, message: this.$t('enter') + ' ' + this.$t('password') }],
        },
        confirmPassword: {
          validateTrigger: 'blur',
          rules: [
            { required: true, message: this.$t('enter') + ' ' + this.$t('confirmPassword') },
            {
              validator: (rule, value, callback) => {
                const form = this.basicForm
                if (value && value !== form.getFieldValue('password')) {
                  callback(
                    this.languageStorage == 'en-US'
                      ? 'Two passwords that you enter is inconsistent!'
                      : '两次密码不能相同'
                  )
                } else {
                  callback()
                }
              },
            },
          ],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['nickname', 'avatar']),
    ...mapGetters('language', ['languageStorage']),
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions(['Logout', 'LogoutV1', 'modifyPassword']),
    handleLogout() {
      this.$confirm({
        title: this.$t('tips'),
        content: this.$t('home.signOutInfo'),
        onOk: () => {
          return this.Logout({})
            .then(() => {
              this.$router.push(`/user/login`)
            })
            .catch((err) => {
              this.$message.error({
                title: this.$t('error'),
                description: err.message,
              })
            })
        },
        onCancel() {},
      })
    },
    handleModify() {
      this.visible = true
    },
    handleClosePassword() {
      this.basicForm.resetFields()
    },
    submitPassword() {
      // 触发表单验证
      this.basicForm.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true
          this.modifyPassword(values)
            .then((res) => {
              this.$message.success(this.$t('success'))
              console.log('---------->1')
              this.visible = false
              console.log('---------->2')

              this.LogoutV1({})
                .then(() => {
                  this.$router.push(`/user/login`)
                })
                .catch((err) => {
                  this.$message.error({
                    title: this.$t('error'),
                    description: err.message,
                  })
                })
            })
            .catch((e) => {
              this.$message.error(e.message)
              this.visible = false
            })
            .finally(() => {
              this.confirmLoading = false
              this.handleClosePassword()
            })
        }
      })
    },
  },
}
</script>
