import { axios } from '@/utils/request'

export function getAllTask(parameter = {}) {
  return axios({
    url: '/examineTask/allTask',
    method: 'post',
    data: parameter,
  })
}

export function getExamineUser(parameter = {}) {
  return axios({
    url: '/examineTask/listExamineUser',
    method: 'post',
    data: parameter,
  })
}

export function modExamineTaskUser(parameter = {}) {
  return axios({
    url: '/examineTask/changeUser',
    method: 'post',
    data: parameter,
  })
}

export function getMyTask(parameter = {}) {
  return axios({
    url: '/examineTask/myTask',
    method: 'post',
    data: parameter,
  })
}

export function getHistoryTask(parameter = {}) {
  return axios({
    url: '/examineTask/historyTask',
    method: 'post',
    data: parameter,
  })
}
export function getPerformance(parameter = {}) {
  return axios({
    url: '/examineTask/performance',
    method: 'post',
    data: parameter,
  })
}
export function auditApproval(parameter = {}) {
  return axios({
    url: '/examine/submit',
    method: 'post',
    data: parameter,
  })
}
export function getApprovalListItem(parameter = {}) {
  return axios({
    url: '/examine/listItem',
    method: 'post',
    data: parameter,
  })
}
export function getListUserWithNum(parameter = {}) {
  return axios({
    url: '/examineTask/listUserWithNum',
    method: 'post',
    data: parameter,
  })
}
export function batchChangeUser(parameter = {}) {
  return axios({
    url: '/examineTask/changeUsers',
    method: 'post',
    data: parameter,
  })
}
