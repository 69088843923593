import { axios } from '@/utils/request'

export function getAllCollectionTask(parameter = {}) {
  return axios({
    url: '/platform/group/collectionTask/allTask',
    method: 'post',
    data: parameter,
  })
}

export function getCollectionUser(parameter = {}) {
  return axios({
    url: '/platform/group/collectionTask/listCollectionUser',
    method: 'post',
    data: parameter,
  })
}

export function modCollectionTaskUser(parameter = {}) {
  return axios({
    url: '/platform/group/collectionTask/changeUser',
    method: 'post',
    data: parameter,
  })
}

export function followCollection(parameter = {}) {
  return axios({
    url: '/platform/group/collectionTask/follow',
    method: 'post',
    data: parameter,
  })
}
export function getListUserWithNum(parameter = {}) {
  return axios({
    url: '/platform/group/collectionTask/listUserWithNum',
    method: 'post',
    data: parameter,
  })
}
export function batchChangeUser(parameter = {}) {
  return axios({
    url: '/platform/group/collectionTask/changeUsers',
    method: 'post',
    data: parameter,
  })
}
