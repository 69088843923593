import CONST_REQUEST from '../constants/request'
import DICT_CODE from '../constants/dict-code'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      DICT_CODE,
    }
  },
  computed: {
    ...mapGetters('dict', ['dictListStatus']),

    dict() {
      return {
        DICT_CODE,
        loading: this.dictListStatus.code == CONST_REQUEST.LOADING,
        success: this.dictListStatus.code == CONST_REQUEST.SUCCESS,
        failure: this.dictListStatus.code == CONST_REQUEST.FAILURE,
        data: this.dictListStatus.data || {},
        cache: {},
      }
    },
  },
  methods: {
    ...mapActions('dict', { _getDict: 'getDictList' }),

    getDict() {
      if (!this.dict.loading && !this.dict.success) {
        return this._getDict()
      } else {
        return Promise.resolve(this.dict)
      }
    },
    getDictList(type) {
      return this.dict.data[type] || []
    },
    buildDict(type) {
      const { cache } = this.dict

      return (
        cache[type] ||
        (cache[type] = this.getDictList(type).reduce((obj, item) => {
          obj[item.itemValue] = item

          return obj
        }, {}))
      )
    },
    getDictType(type) {
      return this.buildDict(type)
    },
    getDictItem(type, value) {
      return this.getDictType(type)[value]
    },
    getDictItemName(type, value, key = 'itemText') {
      return type && ((this.getDictItem(type, value) || {})[key] || '')
    },
    getDictItemNames(type, values) {
      return (values || []).map((value) => this.getDictItemName(type, value))
    },
  },
}
