import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import cookie from './cookie'
import CODE_REQUEST from '@/constants/request'
import { message } from 'ant-design-vue/es'
const getHeaders = () => {
  return {
    token: cookie.get('token'),
    country: 'public',
  }
}

let webpublic = ''

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // api base_url
})

const err = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = cookie.get('token')
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message,
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed',
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use((config) => {
  // console.log(1111111111111, config,store.state.urlHeader)
  config.headers = {
    ...config.headers,
    ...getHeaders(),
    country: config.publicHeader ? config.publicHeader : sessionStorage.getItem('country') || 'public',
    //动态header区分国家
  }

  // support post form data
  if (config.type === 'form') {
    const json = config.data || {}
    let formData = new FormData()
    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        formData.append(key, json[key])
      }
    }
    config.data = formData
  }
  // if (config.url.indexOf('user/routes')>0){
  //   config.baseURL = process.env.VUE_APP_API_BASE_URL+ '/web'
  // }else{
  //   config.baseURL = process.env.VUE_APP_API_BASE_URL+ '/web' + webpublic
  // }
  // config.baseURL = process.env.VUE_APP_API_BASE_URL
  return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  // debugger
  const data = response.data || {}
  if (data.code != '2000') {
    // console.log(1111111111, response.data)
    // message.error(data.msg)
  }
  if (data.code == CODE_REQUEST.CODE_UNLOGIN) {
    cookie.delete('token')
    store.dispatch('clearUserInfo')
    setTimeout(() => {
      window.location.href = window.location.origin + '/user/login'
    }, 500)
    // setTimeout(() => {
    //   window.location.reload();
    // }, 16)
  }
  return response.data
}, err)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service)
  },
}

export { installer as VueAxios, service as axios, getHeaders }
