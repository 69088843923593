import Vue from 'vue'
import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import notification from 'ant-design-vue/es/notification'
import { setDocumentTitle, domTitle } from '@/utils/domUtil'
import lang from '@/i18n/lang'
import cookie from './utils/cookie'
import i18n from './i18n/'
NProgress.configure({ showSpinner: false }) // NProgress Configuration
const whiteList = ['login', 'register', 'registerResult'] // no redirect whitelist
router.beforeEach((to, from, next) => {
  const token = cookie.get('token')
  NProgress.start() // start progress bar
  to.meta &&
    typeof to.meta.title !== 'undefined' &&
    setDocumentTitle(
      `${store.getters['language/languageStorage'] == lang.zhCN ? to.meta.title : to.meta.enTitle} - ${domTitle}`
    )
  if (token) {
    /* has token */
    if (to.path === '/user/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      const hasMenus = store.getters.menus && store.getters.menus.length > 0
      if (hasMenus) {
        next()
      } else {
        store
          .dispatch('GetInfo')
          .then((res) => {
            const menus = res.data && res.data.menus
            if (!menus || !menus.length) {
              throw new Error('No permissions Please contact Administrators')
              next({ path: '/user/login' })
            }
            store.dispatch('GenerateRoutes', { menus }).then(() => {
              // 动态添加可访问路由表
              router.addRoutes(store.getters.addRouters)
              const findFirstPath = (menu, round) => {
                let path = menu[0].menuPath
                if (round == 1) return path
                if (menu[0].children && menu[0].children.length) {
                  path = findFirstPath(menu[0].children, round - 1)
                }
                return path
              }
              const path = !to.path || to.path == '/' ? findFirstPath(menus) : to.path
              next({ path, replace: true })
            })
          })
          .catch((e) => {
            notification.error({
              message: `${i18n.tc('error')}`,
              description: `${i18n.tc('home.userInfoFail')}`,
            })
            cookie.delete('token')
            next({ path: '/' })
          })
      }
    }
  } else {
    if (whiteList.includes(to.name)) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next({ path: '/user/login', query: { redirect: to.fullPath } })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
