import { axios } from '@/utils/request'

export function getAllRemindTask(parameter = {}) {
  return axios({
    url: '/remindTask/allTask',
    method: 'post',
    data: parameter,
  })
}

export function getRemindUser(parameter = {}) {
  return axios({
    url: '/remindTask/listRemindUser',
    method: 'post',
    data: parameter,
  })
}

export function modRemindTaskUser(parameter = {}) {
  return axios({
    url: '/remindTask/changeUser',
    method: 'post',
    data: parameter,
  })
}

export function getMyRemindTask(parameter = {}) {
  return axios({
    url: '/remindTask/myTask',
    method: 'post',
    data: parameter,
  })
}

export function getHistoryRemindTask(parameter = {}) {
  return axios({
    url: '/remindTask/historyTask',
    method: 'post',
    data: parameter,
  })
}

export function getRemindPerformance(parameter = {}) {
  return axios({
    url: '/remindTask/performance',
    method: 'post',
    data: parameter,
  })
}

export function followRemind(parameter = {}) {
  return axios({
    url: '/remindTask/follow',
    method: 'post',
    data: parameter,
  })
}
export function getListUserWithNum(parameter = {}) {
  return axios({
    url: '/remindTask/listUserWithNum',
    method: 'post',
    data: parameter,
  })
}
export function batchChangeUser(parameter = {}) {
  return axios({
    url: '/remindTask/changeUsers',
    method: 'post',
    data: parameter,
  })
}
export function getRemindLogList(parameter = {}) {
  return axios({
    url: '/remindLog/list',
    method: 'post',
    data: parameter,
  })
}
