const getters = {
  device: (state) => state.app.device,
  theme: (state) => state.app.theme,
  color: (state) => state.app.color,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  nickname: (state) => state.user.name,
  welcome: (state) => state.user.welcome,
  roles: (state) => state.user.roles,
  menus: (state) => state.user.menus,
  merchantId: (state) => state.user.merchantId,
  merchantNo: (state) => state.user.merchantNo,
  sysUserId: (state) => state.user.sysUserId,
  userInfo: (state) => state.user.info,
  addRouters: (state) => state.permission.addRouters,
  routers: (state) => state.permission.routers,
  multiTab: (state) => state.app.multiTab,
  lang: (state) => state.i18n.lang,
  urlHeader: (state) => state.urlHeader,
  userNo: (state) => state.user.userNo,
  userId: (state) => state.user.userId,
}

export default getters
