import api from './index'
import { axios } from '@/utils/request'
const publicHeader = 'public'

export function login(parameter) {
  return axios({
    url: '/login',
    method: 'post',
    data: parameter,
    publicHeader,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  })
}

export function logout() {
  return axios({
    url: '/logout',
    method: 'post',
    publicHeader,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  })
}

export function modifyPassword(payload = {}) {
  return axios({
    url: '/user/modifyPwd',
    method: 'post',
    publicHeader,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    data: payload,
  })
}

export function getPermissionsRoutes(parameter = {}) {
  return axios({
    url: 'index/routes',
    method: 'post',
    data: parameter,
    publicHeader,
  })
}
