<template>
  <!-- <a-dropdown>
    <span class="action global-lang">
      <a-icon type="flag" style="font-size: 16px" />
    </span>
    <a-menu v-model="defaultCountry" slot="overlay" style="width: 150px">
      <a-menu-item v-for="item in country" :key="item.itemValue" @click="onSwitchCountry(item)">
        <a rel="noopener noreferrer">
          {{ item.itemText }}
        </a>
      </a-menu-item>
    </a-menu>
  </a-dropdown> -->
  <a-select v-model="defaultCountry" style="width: 100px; margin-right: 20px;" >
    <a-select-option v-for="item in country" :key="item.itemValue" :value="item.itemValue" @click="onSwitchCountry(item)">
      {{ item.itemText }}
    </a-select-option>
  </a-select>
</template>

<script>
import { findDictItemByCode } from '@/api/pay'

export default {
  name: 'CountrySelect',
  data() {
    return {
      country: [],
      defaultCountry: [sessionStorage.getItem('country') || ''],
    }
  },
  created() {
    findDictItemByCode('country').then((res) => {
      this.country = res.data.country

      if (!sessionStorage.getItem('country') && this.country.length > 0) {
        this.defaultCountry = [this.country[0].itemValue]
        sessionStorage.setItem('country', this.country[0].itemValue)
        sessionStorage.setItem('countryName', this.country[0].itemText)
      }
    })
  },
  methods: {
    onSwitchCountry(country) {
      console.log(country)
      this.defaultCountry = [country.itemValue]
      sessionStorage.setItem('country', country.itemValue)
      sessionStorage.setItem('countryName', country.itemText)
      this.$store.dispatch('toggleCountry', country.itemValue, country.itemText)
      window.location.reload()
    },
  },
}
</script>
