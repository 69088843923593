// eslint-disable-next-line
import { UserLayout, BasicLayout, RouteView, BlankLayout, PageView } from '@/layouts'
import { bxAnaalyse } from '@/core/icons'
import i18n from '../i18n/'

export const asyncRouterMap = [
  {
    path: '/',
    name: 'home',
    component: BasicLayout,
    meta: { title: 'home.title' },
    redirect: '/homepage',
    children: [
      {
        path: '/homepage',
        name: 'homepage',
        component: () => import('../views/dashboard/Homepage.vue'),
        meta: { title: '首页', keepAlive: true },
      },
      // 系统管理
      {
        path: '/system',
        name: 'system',
        redirect: '/system/menu/list',
        component: PageView,
        meta: { title: 'system.title', icon: 'setting', permission: ['user'] },
        children: [
          {
            path: '/system/menu/list',
            name: 'menuList',
            hideChildrenInMenu: true, // 强制显示 MenuItem 而不是 SubMenu
            component: () => import('../views/system/MenuList.vue'),
            meta: { title: 'system.menuList.title', keepAlive: true, permission: ['user'] },
          },
          {
            path: '/system/role/list',
            name: 'roleList',
            component: () => import('../views/system/RoleList.vue'),
            meta: { title: 'system.roleList.title', keepAlive: true, permission: ['role'] },
          },
          {
            path: '/system/user/list',
            name: 'userList',
            component: () => import('../views/system/UserList.vue'),
            meta: { title: 'system.userList.title', keepAlive: true, permission: ['user'] },
          },
          {
            path: '/system/user/collist',
            name: 'colUserList',
            component: () => import('../views/system/ColUserList.vue'),
            meta: { title: 'system.userList.title', keepAlive: true, permission: ['user'] },
          },
          {
            path: '/system/datadict',
            name: 'datadict',
            component: () => import('../views/system/Datadict.vue'),
            meta: { title: '数据字典', keepAlive: true },
          },
          {
            path: '/system/appList',
            name: 'appList',
            component: () => import('../views/system/AppList.vue'),
            meta: { title: '应用管理', keepAlive: true },
          },
        ],
      },
    ],
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true,
  },
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login'),
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register'),
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult'),
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined,
      },
    ],
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/404'),
  },
]
