export default {
  zh: {
    assign: '制定分配',
    teleMarketUser: '电销员',
    days: '时间范围',
    days_today: '今天',
    days_yesterday: '昨天',
    days_last3days: '最近3天',
    days_last7days: '最近7天',
    days_last30days: '最近30天',
    days_other: '自定义',
    createdTime: '自定义时间',
    transfer: '转单',
    completed: '已完成',
    donotFollowUp: '未跟进',
    followUping: '已跟进',
    type: '分销类型',
    registerTime: '注册时间',
    allTypes: '全部类型',
    allUsers: '全部人员',
    type_undefined: ' ',
    type_1: '发送验证码未登录',
    type_2: '基本信息未提交',
    type_3: '联系人未提交',
    type_4: 'ID卡未提交',
    type_5: '银行卡未提交',
    type_6: '订单未提交',
    type_7: '结清1-7天未转化',
    type_8: '提前结清(新客)',
    type_9: '提前结清(老客)',
    type_10: '注册未申请',
    followStatus_0: '未跟进',
    followStatus_1: '已跟进',
    followStatus_2: '已完成',
    assignTime: '分配时间',
    followStatus: '状态',
    mobile: '手机号',
    email: '邮箱',
    assignDefaultShow: '默认为0',
    seatMobile: '坐席本系统账号',
    seat: '坐席登录账号',
    seatStatus: '坐席状态',
    seatStatus_0: '有效',
    seatStatus_1: '无效',
    operate: '操作',
    createTime: '创建时间',
    updateTime: '更新时间',
    id: 'ID',
    convertStatus: '转化状态',
    convertStatus_0: '未转化',
    convertStatus_1: '已转化',
    dataDate: '日期',
    app: '包名',
    t1Convert: '转化数',
    assignNum: '当日分配数',
    loadNum: '负载数',
    convertNum: '转化数',
    recycle: '回收',
    reAssign: '再分配',
    assignStatus: '分配状态',
    assignStatus_0: '未分配',
    assignStatus_1: '已分配',
    assignStatus_2: '已回收',
    recycleNum: '回收单量',
    all: '全部',
    lastSettleTime: '上次结清时间',
    appSsidNotice: '请选择包',
    convertRate: '转化率',
    totalAssignNum: '首复贷总分配数',
    totalConvertNum: '首复贷总转化数',
    totalConvertRate: '首复贷总转化率',
    averageAssignNum: '首复贷平均日分配数',
    averageConvertNum: '首复贷平均日转化数',
    loanType: '业务类型',
    loanType_1: '首贷',
    loanType_2: '复贷',
    loanType_3: '首复贷',
    loanType_4: '提前结清',
    passNum: '通过数',
    passRate: '通过率',
    summary: '汇总',
    convertNumT0: 'T0转化数',
    convertNumT1: 'T1转化数',
    convertNumT2: 'T2转化数',
    convertNumT3: 'T3转化数',
    convertNumT4: 'T4转化数',
    convertNumT5: 'T5转化数',
    convertNumT6: 'T6转化数',
    convertNumT7: 'T7转化数',
    convertRateT0: 'T0转化率',
    convertRateT1: 'T1转化率',
    convertRateT2: 'T2转化率',
    convertRateT3: 'T3转化率',
    convertRateT4: 'T4转化率',
    convertRateT5: 'T5转化率',
    convertRateT6: 'T6转化率',
    convertRateT7: 'T7转化率',
    passNumT0: 'T0通过数',
    passNumT1: 'T1通过数',
    passNumT2: 'T2通过数',
    passNumT3: 'T3通过数',
    passNumT4: 'T4通过数',
    passNumT5: 'T5通过数',
    passNumT6: 'T6通过数',
    passNumT7: 'T7通过数',
    passRateT0: 'T0通过率',
    passRateT1: 'T1通过率',
    passRateT2: 'T2通过率',
    passRateT3: 'T3通过率',
    passRateT4: 'T4通过率',
    passRateT5: 'T5通过率',
    passRateT6: 'T6通过率',
    passRateT7: 'T7通过率',
    followUp: '跟进',
    followUpStatus: '跟进状态',
    followUpStatus_0: '没接通',
    followUpStatus_1: '没兴趣',
    followUpStatus_2: '已申请借款',
    followUpStatus_3: '有可能申请借款',
    followUpStatus_4: '需再次跟进',
    followUpStatus_5: '电话可联',
    followUpStatus_6: 'wa可联',
    followUpStatus_7: '邮箱可联',
    followUpStatus_8: '账号不存在',
    followUpStatus_a: '本人接听',
    followUpStatus_b: '非本人接听',
    followUpStatus_c: '无人接听',
    followUpStatus_d: '空号',
    followUpStatus_e: '关机',
    followUpStatus_f: '停机',
    followUpStatus_g: 'Whatsapp承诺准时还款',
    followUpStatus_h: 'Whatsapp未回复',
    followUpStatus_i: '没有whatsapp账号',
    followUpStatus_j: '待跟进',
    userName: '名字',
    baseInfoSubmitTime: '基本信息提交时间',
    linkManSubmitTime: '联系人信息提交时间',
    idInfoSubmitTime: 'ID信息提交时间',
    bankInfoSubmitTime: '银行卡提交时间',
    orderSubmitTime: '订单提交时间',
    userDetail: '用户信息',
    teleMarketDetail: '电销详情',
    userOperateHistory: '用户操作历史',
    followUpHistory: '跟进历史',
    followUpTime: '跟进时间',
    followUpUser: '跟进人',
    remark: '备注',
    attachments: '附件',
    attachment: '附件',
    imgCountLimitNotice: '最多只能上传4张图片',
    uploadImage: '上传图片',
    call: '拨号',
    upload: '上传',
    followUpNotice: '请选择跟进状态',
    nxAccount: '牛信账号',
    nxPassword: '牛信密码',
    imageLoadingNotice: '图片上传中...',
    type_8_pre: '提前',
    type_8_suf: '天结清(新客)',
    earlySettleNotice: '提前结清需要正确填写提前天数',
    earlySettleNum: '提前结清数',
    earlySettleRate: '提前结清率',
    totalEarlySettleAssignNum: '提前结清总分配数',
    totalEarlySettleNum: '提前结清总转化数',
    totalEarlySettleRate: '提前结清总转化率',
    averageEarlySettleAssignNum: '提前结清平均日分配数',
    averageEarlySettleNum: '提前结清平均日转化数',
    notApplyReason: '未申贷原因',
    notApplyReason_a: '利率高',
    notApplyReason_b: '金额低',
    notApplyReason_c: '期限短',
    notApplyReason_d: '审批时间长',
    notApplyReason_e: '催收',
    notApplyReason_f: '负面评价多',
    notApplyReason_g: '获取个人信息',
    notApplyReason_h: '不需要贷款',
    notApplyReason_i: 'APP Bug',
    notApplyReason_j: '错误下载了APP',
    notApplyReason_k: '其他',
    type_9_pre: '提前',
    type_9_suf: '天结清(老客)',
    lastApplyTime: '最近申请时间',
    exportTagData: '导出标签',
    downloadTagDataNotice: '请选择跟进时间',
    lastFollowUpTime: '最近一次跟进时间',
    lastFollowUpStatus: '最近一次跟进状态',
    lastNotApplyReason: '最近一次跟进未申贷原因',
    lastFollowRemark: '最近一次跟进备注',
    createGroup: '生成用户群',
    groupName: '用户群名称',
    noGroupNameNotice: '请输入用户群名称',
    noSelectUserNotice: '请选择电销员',
    noTypeCountNotice: '请填写类型数量',
    type_10_pre: '注册',
    type_10_suf: '小时以上未申请',
    registerNoApplyNotice: '注册未申请需要正确填写小时数',
    scrmExport: 'SCRM导出',
    exportType: '导出类型',
    exportType_t1_convert: 'T1转化',
    duration: '期限(仅用于提前结清)',
    duration_7: '7天',
    duration_14: '14天',
    duration_15: '15天',
    duration_21: '21天',
    copy: '复制',
    repayUrl: '还款链接',
    allDuration: '全部期限',
  },
  en: {
    assign: 'assign',
    teleMarketUser: 'Telemarketer',
    days: 'time limit',
    days_today: 'today',
    days_yesterday: 'yesterday',
    days_last3days: 'last 3 days',
    days_last7days: 'last 7 days',
    days_last30days: 'last 30 days',
    days_other: 'customize',
    createdTime: 'customize',
    transfer: 'Turn order',
    completed: 'completed',
    donotFollowUp: 'Not followed up',
    followUping: 'Have followed up',
    type: 'Telemarketing type',
    registerTime: 'Registration time',
    allTypes: 'all types',
    allUsers: 'all staff',
    type_undefined: ' ',
    type_1: 'Send verification code Not logged in',
    type_2: 'Basic information not submitted',
    type_3: 'Contact not submitted',
    type_4: 'ID card not submitted',
    type_5: 'Bank card not submitted',
    type_6: 'Order not submitted',
    type_7: 'Cleared 1-7 days without conversion',
    type_8: 'Early settlement(new customer)',
    type_9: 'Early settlement(old customer)',
    type_10: 'Registration not applied',
    followStatus_0: 'Not followed up',
    followStatus_1: 'Have followed up',
    followStatus_2: 'completed',
    assignTime: 'Assign time',
    followStatus: 'status',
    mobile: 'Mobile',
    email: 'Email',
    assignDefaultShow: 'Default is 0',
    seatMobile: 'Local Account',
    seat: 'Seat Account',
    seatStatus: 'seat Status',
    seatStatus_0: 'enable',
    seatStatus_1: 'disable',
    operate: 'Operate',
    createTime: 'Create Time',
    updateTime: 'Update Time',
    id: 'ID',
    convertStatus: 'conversion state',
    convertStatus_0: 'not converted',
    convertStatus_1: 'converted',
    dataDate: 'Date',
    app: 'Package Name',
    t1Convert: 'conversions',
    assignNum: 'Distribution of the day',
    loadNum: 'load number',
    convertNum: 'conversions',
    recycle: 'Recycle',
    reAssign: 'Re Assign',
    assignStatus: 'Assign Status',
    assignStatus_0: 'Not assigned',
    assignStatus_1: 'Assigned',
    assignStatus_2: 'Recycled',
    recycleNum: 'Recycling quantity',
    all: 'All',
    lastSettleTime: 'Last Settle Time',
    appSsidNotice: 'Please select Package Name',
    convertRate: 'Convert Rate',
    totalAssignNum: 'total loan allocation',
    totalConvertNum: 'total loan conversions',
    totalConvertRate: 'total loan conversion rate',
    averageAssignNum: 'average loan daily allocation',
    averageConvertNum: 'average loan daily conversions',
    loanType: 'Business Type',
    loanType_1: 'First Loan',
    loanType_2: 'Reloan',
    loanType_3: 'First Loan And ReLoan',
    loanType_4: 'Early settlement',
    passNum: 'pass num',
    passRate: 'pass rate',
    summary: 'summary',
    convertNumT0: 'T0 conversions',
    convertNumT1: 'T1 conversions',
    convertNumT2: 'T2 conversions',
    convertNumT3: 'T3 conversions',
    convertNumT4: 'T4 conversions',
    convertNumT5: 'T5 conversions',
    convertNumT6: 'T6 conversions',
    convertNumT7: 'T7 conversions',
    convertRateT0: 'T0 Convert Rate',
    convertRateT1: 'T1 Convert Rate',
    convertRateT2: 'T2 Convert Rate',
    convertRateT3: 'T3 Convert Rate',
    convertRateT4: 'T4 Convert Rate',
    convertRateT5: 'T5 Convert Rate',
    convertRateT6: 'T6 Convert Rate',
    convertRateT7: 'T7 Convert Rate',
    passNumT0: 'T0 pass num',
    passNumT1: 'T1 pass num',
    passNumT2: 'T2 pass num',
    passNumT3: 'T3 pass num',
    passNumT4: 'T4 pass num',
    passNumT5: 'T5 pass num',
    passNumT6: 'T6 pass num',
    passNumT7: 'T7 pass num',
    passRateT0: 'T0 pass Rate',
    passRateT1: 'T1 pass Rate',
    passRateT2: 'T2 pass Rate',
    passRateT3: 'T3 pass Rate',
    passRateT4: 'T4 pass Rate',
    passRateT5: 'T5 pass Rate',
    passRateT6: 'T6 pass Rate',
    passRateT7: 'T7 pass Rate',
    followUp: 'follow up',
    followUpStatus: 'follow up status',
    followUpStatus_0: 'not connected',
    followUpStatus_1: 'Not interested in',
    followUpStatus_2: 'Applied for a loan',
    followUpStatus_3: 'It is possible to apply for a loan',
    followUpStatus_4: 'Need to follow up again',
    followUpStatus_5: 'available by phone',
    followUpStatus_6: 'wa available',
    followUpStatus_7: 'Email available',
    followUpStatus_8: 'Account does not exist',
    followUpStatus_a: 'Answer in person',
    followUpStatus_b: 'Not in person',
    followUpStatus_c: 'No answer',
    followUpStatus_d: 'Empty number',
    followUpStatus_e: 'Turn off the phone',
    followUpStatus_f: 'Disconnected',
    followUpStatus_g: 'Promise to pay on time on Whatsapp',
    followUpStatus_h: 'No reply on Whatsapp',
    followUpStatus_i: 'No whatsapp account',
    followUpStatus_j: 'To be followed up',
    userName: 'name',
    baseInfoSubmitTime: 'Basic information submission time',
    linkManSubmitTime: 'Contact Information Submission Time',
    idInfoSubmitTime: 'ID information submission time',
    bankInfoSubmitTime: 'Bank card submission time',
    orderSubmitTime: 'order submission time',
    userDetail: 'User Info',
    teleMarketDetail: 'Telemarketing details',
    userOperateHistory: 'User Operation History',
    followUpHistory: 'follow up history',
    followUpTime: 'follow up time',
    followUpUser: 'follow up User',
    remark: 'Remark',
    attachments: 'attachment',
    attachment: 'attachment',
    imgCountLimitNotice: 'You can only upload up to 4 pictures',
    uploadImage: 'upload image',
    call: 'dial',
    upload: 'Upload',
    followUpNotice: 'Please select a follow up status',
    nxAccount: 'NIUXIN account',
    nxPassword: 'NIUXIN password',
    imageLoadingNotice: 'Image uploading...',
    type_8_pre: '',
    type_8_suf: ' day early settlement(new customer)',
    earlySettleNotice: 'Early settlement needs to fill in the number of days in advance',
    earlySettleNum: 'Early settlement num',
    earlySettleRate: 'Early clearance rate',
    totalEarlySettleAssignNum: 'Early settlement of total allocations',
    totalEarlySettleNum: 'Early settlement of total conversions',
    totalEarlySettleRate: 'Early closing total conversion rate',
    averageEarlySettleAssignNum: 'Early settlement of average daily allotments',
    averageEarlySettleNum: 'Early Closing Average Daily Conversions',
    notApplyReason: 'Reason for not applying for a loan',
    notApplyReason_a: 'High interest rate',
    notApplyReason_b: 'Low amount',
    notApplyReason_c: 'Short repayment time',
    notApplyReason_d: 'Long approval time',
    notApplyReason_e: 'violent collection',
    notApplyReason_f: 'Many negative reviews',
    notApplyReason_g: 'access to personal information',
    notApplyReason_h: 'No need for a loan',
    notApplyReason_i: 'App bug',
    notApplyReason_j: 'Downloaded the app by mistake',
    notApplyReason_k: 'other reason',
    type_9_pre: '',
    type_9_suf: ' day early settlement(old customer)',
    lastApplyTime: 'latest application time',
    exportTagData: 'Export label',
    downloadTagDataNotice: 'Please select follow up time',
    lastFollowUpTime: 'Last follow up time',
    lastFollowUpStatus: 'Last follow-up status',
    lastNotApplyReason: 'The reason for not applying for a loan in the latest follow-up',
    lastFollowRemark: 'Last follow up note',
    createGroup: 'generate user group',
    groupName: 'user group name',
    noGroupNameNotice: 'Please enter user group name',
    noSelectUserNotice: 'Please select telemarketers',
    noTypeCountNotice: 'Please enter the type quantity',
    type_10_pre: 'Registered for more than ',
    type_10_suf: ' hours without application',
    registerNoApplyNotice: 'Registration without application needs to fill in the correct number of hours',
    scrmExport: 'SCRM export',
    exportType: 'Export type',
    exportType_t1_convert: 'T1 Convert',
    duration: 'Duration(Only for early settlement)',
    duration_7: '7 Days',
    duration_14: '14 Days',
    duration_15: '15 Days',
    duration_21: '21 Days',
    copy: 'Copy',
    repayUrl: 'Repay Url',
    allDuration: 'all duration',
  },
  es: {
    assign: 'asignar',
    teleMarketUser: 'Vendedor telefónico',
    days: 'límite de tiempo',
    days_today: 'hoy',
    days_yesterday: 'ayer',
    days_last3days: 'últimos 3 días',
    days_last7days: 'últimos 7 días',
    days_last30days: 'últimos 30 días',
    days_other: 'personalizar',
    createdTime: 'personalizar',
    transfer: 'orden de giro',
    completed: 'terminado',
    donotFollowUp: 'no seguido',
    followUping: 'Han seguido',
    type: 'tipo de telemercadeo',
    registerTime: 'tiempo de registro',
    allTypes: 'todos los tipos',
    allUsers: 'Todo el personal',
    type_undefined: ' ',
    type_1: 'Enviar código de verificación No iniciado sesión',
    type_2: 'Información básica no enviada',
    type_3: 'Contacto no enviado',
    type_4: 'DNI no presentado',
    type_5: 'Tarjeta bancaria no enviada',
    type_6: 'Pedido no enviado',
    type_7: 'Despejado 1-7 días sin conversión',
    type_8: 'liquidación anticipada(nuevo cliente)',
    type_9: 'liquidación anticipada(viejo cliente)',
    type_10: 'Registro no aplicado',
    followStatus_0: 'no seguido',
    followStatus_1: 'Han seguido',
    followStatus_2: 'terminado',
    assignTime: 'Asignar tiempo',
    followStatus: 'estado',
    mobile: 'Número de teléfono',
    email: 'Correo',
    assignDefaultShow: 'El valor predeterminado es 0',
    seatMobile: 'cuenta local',
    seat: 'cuenta de asiento',
    seatStatus: 'Estado del agente',
    seatStatus_0: 'permitir',
    seatStatus_1: 'desactivar',
    operate: 'Operación',
    createTime: 'Tiempo de creación',
    updateTime: 'tiempo de actualización',
    id: 'ID',
    convertStatus: 'estado de conversión',
    convertStatus_0: 'no convertido',
    convertStatus_1: 'convertido',
    dataDate: 'fecha',
    app: 'Nombres de paquetes',
    t1Convert: 'conversiones',
    assignNum: 'Distribución del día',
    loadNum: 'número de carga',
    convertNum: 'conversiones',
    recycle: 'Reciclar',
    reAssign: 'reasignar',
    assignStatus: 'Estado de asignación',
    assignStatus_0: 'no asignado',
    assignStatus_1: 'Asignado',
    assignStatus_2: 'Reciclado',
    recycleNum: 'Cantidad de reciclaje',
    all: 'todo',
    lastSettleTime: 'Última hora despejada',
    appSsidNotice: 'Seleccione el nombre del paquete',
    convertRate: 'Tasa de conversión',
    totalAssignNum: 'asignación total de préstamos',
    totalConvertNum: 'conversiones totales de préstamos',
    totalConvertRate: 'tasa de conversión total de préstamos',
    averageAssignNum: 'asignación diaria promedio de préstamos',
    averageConvertNum: 'Conversiones diarias promedio de préstamos',
    loanType: 'Tipo de negocio',
    loanType_1: 'primer prestamo',
    loanType_2: 'refinanciación',
    loanType_3: 'Primer préstamo y refinanciación',
    loanType_4: 'liquidación anticipada',
    passNum: 'número de pase',
    passRate: 'Tasa de aprobación',
    summary: 'resumen',
    convertNumT0: 'T0 conversiones',
    convertNumT1: 'T1 conversiones',
    convertNumT2: 'T2 conversiones',
    convertNumT3: 'T3 conversiones',
    convertNumT4: 'T4 conversiones',
    convertNumT5: 'T5 conversiones',
    convertNumT6: 'T6 conversiones',
    convertNumT7: 'T7 conversiones',
    convertRateT0: 'Tasa de conversión T0',
    convertRateT1: 'Tasa de conversión T1',
    convertRateT2: 'Tasa de conversión T2',
    convertRateT3: 'Tasa de conversión T3',
    convertRateT4: 'Tasa de conversión T4',
    convertRateT5: 'Tasa de conversión T5',
    convertRateT6: 'Tasa de conversión T6',
    convertRateT7: 'Tasa de conversión T7',
    passNumT0: 'Número de pase T0',
    passNumT1: 'Número de pase T1',
    passNumT2: 'Número de pase T2',
    passNumT3: 'Número de pase T3',
    passNumT4: 'Número de pase T4',
    passNumT5: 'Número de pase T5',
    passNumT6: 'Número de pase T6',
    passNumT7: 'Número de pase T7',
    passRateT0: 'Tasa de aprobación T0',
    passRateT1: 'Tasa de aprobación T1',
    passRateT2: 'Tasa de aprobación T2',
    passRateT3: 'Tasa de aprobación T3',
    passRateT4: 'Tasa de aprobación T4',
    passRateT5: 'Tasa de aprobación T5',
    passRateT6: 'Tasa de aprobación T6',
    passRateT7: 'Tasa de aprobación T7',
    followUp: 'seguimiento',
    followUpStatus: 'estado de seguimiento',
    followUpStatus_0: 'no conectado',
    followUpStatus_1: 'No estoy interesado en',
    followUpStatus_2: 'Solicitó un préstamo',
    followUpStatus_3: 'Es posible solicitar un préstamo',
    followUpStatus_4: 'Necesito seguir de nuevo',
    followUpStatus_5: 'Teléfono conectable',
    followUpStatus_6: 'wa conectable',
    followUpStatus_7: 'Correo conectable',
    followUpStatus_8: 'La cuenta no existe',
    followUpStatus_a: 'En persona',
    followUpStatus_b: 'No en persona',
    followUpStatus_c: 'Sin respuesta',
    followUpStatus_d: 'Ningún número',
    followUpStatus_e: 'fuera de',
    followUpStatus_f: 'Desconectado',
    followUpStatus_g: 'Promesa de pago puntual en Whatsapp',
    followUpStatus_h: 'Sin respuesta en Whatsapp',
    followUpStatus_i: 'Sin cuenta de whatsapp',
    followUpStatus_j: 'A seguir',
    userName: 'nombre',
    baseInfoSubmitTime: 'Tiempo de envío de información básica',
    linkManSubmitTime: 'Información de contacto Tiempo de envío',
    idInfoSubmitTime: 'Tiempo de envío de información de identificación',
    bankInfoSubmitTime: 'Hora de envío de la tarjeta bancaria',
    orderSubmitTime: 'Tiempo de envío del pedido',
    userDetail: 'Información de usuario',
    teleMarketDetail: 'Detalles de telemercadeo',
    userOperateHistory: 'Historial de operaciones del usuario',
    followUpHistory: 'raíz en la historia',
    followUpTime: 'tiempo de seguimiento',
    followUpUser: 'hacer un seguimiento',
    remark: 'Observación',
    attachments: 'apéndice',
    attachment: 'apéndice',
    imgCountLimitNotice: 'Solo puedes subir hasta 4 fotos',
    uploadImage: 'subir imagen',
    call: 'Llamar',
    upload: 'subir',
    followUpNotice: 'Seleccione un estado de seguimiento',
    nxAccount: 'NIUXIN cuenta',
    nxPassword: 'NIUXIN contraseña',
    imageLoadingNotice: 'Cargando imagen...',
    type_8_pre: '',
    type_8_suf: ' día de liquidación anticipada(nuevo cliente)',
    earlySettleNotice: 'La liquidación anticipada necesita completar el número de días por adelantado',
    earlySettleNum: 'Liquidación anticipada',
    earlySettleRate: 'Tasa de liquidación anticipada',
    totalEarlySettleAssignNum: 'Liquidación anticipada de asignaciones totales',
    totalEarlySettleNum: 'Cierre las conversiones totales anticipadamente',
    totalEarlySettleRate: 'Tasa de conversión total de cierre anticipado',
    averageEarlySettleAssignNum: 'Liquidación anticipada de adjudicaciones medias diarias',
    averageEarlySettleNum: 'Conversiones diarias promedio de cierre anticipado',
    notApplyReason: 'Motivo por el que no solicita un préstamo',
    notApplyReason_a: 'alto tipo de interés',
    notApplyReason_b: 'monto bajo',
    notApplyReason_c: 'Corto plazo de pago',
    notApplyReason_d: 'Largo plazo de aprobación',
    notApplyReason_e: 'Cobranza violenta',
    notApplyReason_f: 'Muchos comentarios negativos',
    notApplyReason_g: 'necesita información personal',
    notApplyReason_h: 'No se requiere préstamo',
    notApplyReason_i: 'error en la aplicación',
    notApplyReason_j: 'descarga app por equivocación',
    notApplyReason_k: 'otros',
    type_9_pre: '',
    type_9_suf: ' día de liquidación anticipada(viejo cliente)',
    lastApplyTime: 'último tiempo de aplicación',
    exportTagData: 'exportación de etiquetas',
    downloadTagDataNotice: 'Seleccione un tiempo de seguimiento',
    lastFollowUpTime: 'Última hora de seguimiento',
    lastFollowUpStatus: 'Estado del último seguimiento',
    lastNotApplyReason: 'El motivo de no solicitar un préstamo en el último seguimiento',
    lastFollowRemark: 'Última nota de seguimiento',
    createGroup: 'generar base de usuarios',
    groupName: 'nombre del grupo de usuarios',
    noGroupNameNotice: 'Ingrese el nombre del grupo de usuarios',
    noSelectUserNotice: 'Seleccione un vendedor telefónico',
    noTypeCountNotice: 'Por favor complete el tipo de cantidad',
    type_10_pre: 'Registrado por más de ',
    type_10_suf: ' horas sin aplicación',
    registerNoApplyNotice: 'El registro sin solicitud debe completar el número correcto de horas',
    scrmExport: 'SCRM exportar',
    exportType: 'tipo de exportación',
    exportType_t1_convert: 'conversión T1',
    duration: 'duración(Sólo para liquidación anticipada)',
    duration_7: '7 días',
    duration_14: '14 días',
    duration_15: '15 días',
    duration_21: '21 días',
    copy: 'Copiar',
    repayUrl: 'URL de pago',
    allDuration: 'toda la duración',
  }
}