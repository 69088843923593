<template>
  <div class="logo">
    <router-link :to="{name:'dashboard'}">
      <!-- <LogoSvg alt="logo" /> -->
      <h1 v-if="showTitle">{{ global }}</h1>
    </router-link>
  </div>
</template>

<script>
// import LogoSvg from '@/assets/logo.png?inline'
// import store from "@/store";

export default {
  name: 'Logo',
  components: {
    // LogoSvg
  },
  props: {
    title: {
      type: String,
      default: 'INX Cloud',
      required: false
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  computed:{
    global: () => {
      // const countryName = sessionStorage.getItem('countryName') || '智利'
      return process.env.VUE_APP_GLOBAL_NAME 
    }
  }
}
</script>
