import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'
import customer from '@/store/modules/customer'
import order from '@/store/modules/order'
import approval from '@/store/modules/approval'
import operation from '@/store/modules/operation'
import collection from '@/store/modules/collection'
import platformCollection from '@/store/modules/platformCollection'
import platformGroup from '@/store/modules/platformGroup'
import remind from '@/store/modules/remind'
import home from '@/store/modules/home'

// default router permission control
import permission from './modules/permission'
import language from './modules/language'
import system from './modules/system'

import dict from './modules/dict'

// dynamic router permission control (Experimental)
// import permission from './modules/async-router'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    permission,
    language,
    system,
    customer,
    dict,
    order,
    approval,
    operation,
    collection,
    platformCollection,
    platformGroup,
    remind,
    home,
  },
  state: {
    urlHeader: 'public',
    countryName: ''
    // urlHeader: sessionStorage.getItem('country') ? sessionStorage.getItem('country') : 'public',
  },
  mutations: {
    TOGGLE_COUNTRY: (state, country, countryName) => {
      state.urlHeader = country
      state.countryName = countryName
    },
  },
  actions: {
    toggleCountry({ commit }, country, countryName) {
      commit('TOGGLE_COUNTRY', country, countryName)
    },
  },
  getters,
})
